import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; 
import './Profile.css';
import altpic from './pfp.png';

function Profile ({onLogout}) {
  const [user, setUser] = useState(null);
  let { userId } = useParams();
 
  useEffect(() => {
    const fetchUserData = async () => {
        try {
            const response = await fetch(`https://server.theroostscsu.org/profile/${userId}`);
            const userData = await response.json();
            setUser(userData);
        } catch (error) {
            console.error('Failed to fetch user data:', error);
        }
    };

    fetchUserData();
  }, [userId]); 

  function ImageWithFallback({ src, fallbackSrc, alt }) {
    const handleError = (event) => {
        console.log('Error loading primary image. Switching to fallback.');
        if (event.target.src !== fallbackSrc) {  
            event.target.src = fallbackSrc;
        }
    };

    return (
        <img src={src} alt={alt} onError={handleError} className='leaderboard-picture'/>
    );
  }

  if (!user) {
    return <div style={{ backgroundColor: '#1E3344', height: '100vh' }}>Loading...
      <button onClick={onLogout}>Logout</button>
    </div>;
}



return (
  <div className='profile-page'>

   <div className='profile-container1'>

   <div className='profile' >

   <ImageWithFallback
       src={user.user.profilePicture}
       fallbackSrc={altpic}
       alt="Profile"
   />

   <h1>{user.user.firstName} {user.user.lastName}</h1>

   <p>{user.user.profile.major} | Class of {user.user.profile.graduationYear} | {user.user.profile.pronouns} </p>
   <p><strong>Bio:</strong> {user.user.profile.bio}</p>
   </div>

   <div className='achievements'>
   <p><strong>{user.user.firstName}'s  Achievements</strong></p>

   <li>Study Buddy</li>
   <li>100 Posts!</li>
   <li>Top Tutor</li>
   <li>Wise Mentor</li>  

   <p><strong>{user.user.firstName}'s  Services</strong></p>

   <li>CSC 207 Tutoring</li>
   <li>CSC 425 Study Group</li>

 </div>
   </div>
 </div>

 
);
};

export default Profile;