import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NotificationBell from './NotificationBell';
import ProfilePicture from './ProfilePicture';
import './navbar.css';

const Navbar = ({ onLogout, userId }) => {
    const [navbarDetails, setNavbarDetails] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('userToken');
                const response = await fetch('https://server.theroostscsu.org/navbar/top-navbar-details', {
                    headers: {
                        'userToken': 'Bearer ' + token
                    }
                });
                const text = await response.text();
                if (response.ok && text.trim().startsWith('{')) {
                    const data = JSON.parse(text);
                    setNavbarDetails(data);
                } else {
                    console.error('Server response:', text);
                }
            } catch (error) {
                console.error('Error fetching navbar details:', error);
                console.log('Error occurred at:', error.stack);
            }
        };

        fetchData();
    }, []);

    if (!navbarDetails) {
        return <div style={{ backgroundColor: '#1E3344', height: '100vh' }}>Loading...</div>;
    }

    return (
        <div className="nav">
            <div className="left">
            <Link to="/homepage"><h1>The Roost</h1></Link>
            </div>
            <div className="right">
                {navbarDetails.isAdmin ? (
                    <><Link to="/admin">
                        <img className="admin-img" src="/images/admin.png" alt="Admin" />
                    </Link><><NotificationBell notifications={navbarDetails.notifications} />
                            <ProfilePicture profilePicture={navbarDetails.profilePicture} onLogout={onLogout} userId={userId}/></></>
                ) : (
                    <><NotificationBell notifications={navbarDetails.notifications} />
                    <ProfilePicture profilePicture={navbarDetails.profilePicture} onLogout={onLogout} userId={userId} /></>
                )}
            </div>
        </div>
    );
};

export default Navbar;
