import DepartmentPageFeed from './DepartmentFeed';
import './DepartmentPage.css';


function DepartmentPage({onLogout}) {

  return (
    <div className='homepage-container'>


      <div className='left-announcements'> <DepartmentPageFeed/> </div>

    </div>
  );
}
export default DepartmentPage;