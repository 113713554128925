import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './mentorSignupForm.css';

function MentorSignupForm() {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const token = localStorage.getItem('userToken');
                const response = await fetch('https://server.theroostscsu.org/navbar/top-navbar-details', {
                    headers: {
                        'userToken': 'Bearer ' + token
                    }
                });
                const text = await response.text(); // Get the response as text
                if (response.ok && text.trim().startsWith('{')) { // Check if the response is ok and the text is a valid JSON string
                    const data = JSON.parse(text); // Parse the response as JSON
                    setUser(data);
                    console.log('User:', data); // Log the user data
                    setLoading(false);
                } else {
                    console.error('Server response:', text); // Log the server response
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };
    
        fetchUser();
    }, []);

    const [role, setRole] = useState('');
    const [academicInterests, setAcademicInterests] = useState([]);
    const [careerGoals, setCareerGoals] = useState([]);
    const [extracurricularActivities, setExtracurricularActivities] = useState([]);
    const [hobbies, setHobbies] = useState([]);
    const [skills, setSkills] = useState([]);
    const [gender, setGender] = useState([]);
    const [availability, setAvailability] = useState('');
    const [communicationMethod, setCommunicationMethod] = useState('');
    const [communicationFrequency, setCommunicationFrequency] = useState('');

    const [academicInterestsImportance, setAcademicInterestsImportance] = useState(1);
    const [careerGoalsImportance, setCareerGoalsImportance] = useState(1);
    const [extracurricularActivitiesImportance, setExtracurricularActivitiesImportance] = useState(1);
    const [hobbiesImportance, setHobbiesImportance] = useState(1);
    const [skillsImportance, setSkillsImportance] = useState(1);
    const [genderImportance, setGenderImportance] = useState(1);

    const [step, setStep] = useState(1);

    const handleNext = (event) => {
        event.preventDefault();
        
        if (step === 1 && role === '' || role === 'Select') {
            alert('Please select a role');
            return;
        }
        if (step === 8 && availability === '') {
            alert('Please enter your availability');
            return;
        }
        if (step === 9 && communicationMethod === '') {
            alert('Please enter a communication method');
            return;
        }
        if (step === 10 && communicationFrequency === '') {
            alert('Please enter a communication frequency');
            return;
        }

        setStep(step + 1);
    };

    const handleBack = (event) => {
        event.preventDefault();
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const handleImportanceChange = (event, setImportance) => {
        let value = parseInt(event.target.value, 10);
        if (value < 1) {
            value = 1;
        } else if (value > 5) {
            value = 5;
        }
        setImportance(value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const matchingCriteria = {
            academicInterests: {
                value: academicInterests.split(',').map(item => item.trim()),
                importance: parseInt(academicInterestsImportance, 10)
            },
            careerGoals: {
                value: careerGoals.split(',').map(item => item.trim()),
                importance: parseInt(careerGoalsImportance, 10)
            },
            extracurricularActivities: {
                value: extracurricularActivities.split(',').map(item => item.trim()),
                importance: parseInt(extracurricularActivitiesImportance, 10)
            },
            hobbies: {
                value: hobbies.split(',').map(item => item.trim()),
                importance: parseInt(hobbiesImportance, 10)
            },
            skills: {
                value: skills.split(',').map(item => item.trim()),
                importance: parseInt(skillsImportance, 10)
            },
            gender: {
                value: gender,
                importance: parseInt(genderImportance, 10)
            },
            availability: availability,
            communicationPreferences: {
                method: communicationMethod,
                frequency: communicationFrequency
            }
        };
        try {

            console.log(role, matchingCriteria, user);
            await axios.post('https://server.theroostscsu.org/signup', { role, matchingCriteria, user: { _id: user._id } });
            navigate('/matches');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="mentor-content">
            <h1>Let's find your mentorship match</h1>
            <form className="mentor-form" onSubmit={handleSubmit}>
                {step === 1 && (
                    <div className="mentor-form-details">
                        <label>Select your role</label>
                        <select value={role} onChange={(event) => setRole(event.target.value)}>
                            <option value="">Select</option>
                            <option value="mentor">Mentor</option>
                            <option value="mentee">Mentee</option>
                        </select>
                    </div>
                )}
                {step === 2 && (
                    <div className="mentor-form-details">
                        <label>
                            What are your academic interests?
                            <input type='text' value={academicInterests} onChange={(event) => setAcademicInterests(event.target.value)} placeholder="e.g., History, Math"/>
                            How important is this to you?
                            <input type='number' min='1' max='5' value={academicInterestsImportance} onChange={(event) => handleImportanceChange(event, setAcademicInterestsImportance)} placeholder="1 - 5"/>
                        </label>
                    </div>
                )}
                {step === 3 && (
                    <div className="mentor-form-details">
                        <label>
                            Have any career goals?
                            <input type='text' value={careerGoals} onChange={(event) => setCareerGoals(event.target.value)} placeholder="e.g., Teacher, Nurse"/>
                            How important is this to you?
                            <input type='number' min='1' max='5' value={careerGoalsImportance} onChange={(event) => handleImportanceChange(event, setCareerGoalsImportance)} placeholder="1 - 5"/>
                        </label>
                    </div>
                )}
                {step === 4 && (
                    <div className="mentor-form-details">
                        <label>
                            Extracurricular activities?
                            <input type='text' value={extracurricularActivities} onChange={(event) => setExtracurricularActivities(event.target.value)} placeholder="e.g., Biology club, Basketball"/>
                            How important is this to you?
                            <input type='number' min='1' max='5' value={extracurricularActivitiesImportance} onChange={(event) => handleImportanceChange(event, setExtracurricularActivitiesImportance)} placeholder="1 - 5"/>
                        </label>
                    </div>
                )}
                {step === 5 && (
                    <div className="mentor-form-details">
                        <label>
                            What do you do in your spare time?
                            <input type='text' value={hobbies} onChange={(event) => setHobbies(event.target.value)} placeholder="e.g., Painting, Gaming"/>
                            How important is this to you?
                            <input type='number' min='1' max='5' value={hobbiesImportance} onChange={(event) => handleImportanceChange(event, setHobbiesImportance)} placeholder="1 - 5"/>
                        </label>
                    </div>
                )}
                {step === 6 && (
                    <div className="mentor-form-details">
                        <label>
                            {role === 'mentor' ? 'What skills would you like to mentor in?' : 'What skills would you like to develop?'}
                            <input type='text' value={skills} onChange={(event) => setSkills(event.target.value)} placeholder="e.g., Time management, Programming"/>
                            How important is this to you?
                            <input type='number' min='1' max='5' value={skillsImportance} onChange={(event) => handleImportanceChange(event, setSkillsImportance)} placeholder="1 - 5"/>
                        </label>
                        </div>
                )}
                {step === 7 && (
                    <div className="mentor-form-details">
                        <label>Would you like to provide your gender?</label>
                        <select value={gender} onChange={(event) => setGender(event.target.value)}>
                            <option value="">Select</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="non-binary">Non-binary</option>
                        </select>
                        <label>How important is this to you?</label>
                        <input type='number' min='1' max='5' value={genderImportance} onChange={(event) => handleImportanceChange(event, setGenderImportance)} placeholder="1 - 5"/>
                    </div>
                )}
                {step === 8 && (
                    <div className="mentor-form-details">
                        <label>
                            When are you available?
                            <input type='text' value={availability} onChange={(event) => setAvailability(event.target.value)} placeholder="e.g., Tuesdays at 5pm" />
                        </label>
                    </div>
                )}
                {step === 9 && (
                    <div className="mentor-form-details">
                        <label>How are you available?</label>
                        <select value={communicationMethod} onChange={(event) => setCommunicationMethod(event.target.value)}>
                            <option value="">Select</option>
                            <option value="email">Email</option>
                            <option value="phone">Phone</option>
                            <option value="video call">Video Call</option>
                            <option value="in person">In Person</option>
                        </select>
                    </div>
                )}

                {step === 10 && (
                    <div className="mentor-form-details">
                        <label>How often would you like to communicate?</label>
                        <select value={communicationFrequency} onChange={(event) => setCommunicationFrequency(event.target.value)}>
                            <option value="">Select</option>
                            <option value="weekly">Weekly</option>
                            <option value="bi-weekly">Bi-weekly</option>
                        </select>
                    </div>
                )}
                <div className="button-wrapper">
                {step > 1 && (
                    <button type='button' className="signup-button" onClick={handleBack}>Back</button>
                )}
                <button type='button' className="signup-button" onClick={step === 10 ? handleSubmit : handleNext}>{step === 10 ? 'Submit' : 'Next'}</button>
                </div>
            </form>
        </div>
    );
}

export default MentorSignupForm;