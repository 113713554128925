import React, { useState } from 'react';
import axios from 'axios';
import './Register.css';
import Select from 'react-select';


const Register = () => {
  const [first_name, setfirst_name] = useState('');
  const [last_name, setlast_name] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [error, setError] = useState(''); 
  const [message, setMessage] = useState('');
  const [stage, setStage] = useState('register');
  const [major, setMajor] = useState(''); 
  const [pronouns, setPronouns] = useState(''); 
  const [bio, setBio] = useState('');
  const [graduationYear, setGraduationYear] = useState(''); 
  const [verificationCode, setVerificationCode] = useState('');
  const [step, setStep] = useState(1);
  

  const handleRegistration = async (event) => {
    event.preventDefault();

    try {
        const response = await axios.post('https://server.theroostscsu.org/auth/register', {
            firstName: first_name,
            lastName: last_name,
            email: email,
            password: password,
            role: role,
            major: major,
            pronouns: pronouns,
            bio: bio,
            graduationYear: graduationYear, 
            profilePicture: 'uploads/' + file.name

        });
        console.log(response.data);
        setError('');
        setMessage('Registration successful. Check email for Verification Code.');
        setStage('verify');
      } catch (error) {
        if(error.response && error.response.data && error.response.data.message) {
          setError(error.response.data.message);
        } else {
          setError();
        }
      }

  };

  const handleVerification = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://server.theroostscsu.org/auth/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, verificationCode }),
      });
      if (response.ok) {
        alert('Email verified successfully! Please log in.');
      } else {
        alert('Verification failed. Please try again.');
      }
      setStage('verify');
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  const [file, setFile] = useState(null);

  const onFileChange = event => {
    setFile(event.target.files[0]);
  };

  const onFileUpload = () => {
    const formData = new FormData();
    formData.append("profileImg", file);

    axios.post("http://localhost:3001/upload", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      console.log("File uploaded successfully", response.data);
    }).catch(error => {
      console.error("Error uploading file", error);
    });

  
  };

  const majorOptions = [
    { value: 'Computer Science', label: 'Computer Science' },
    { value: 'Math', label: 'Math' },
    { value: 'Accounting', label: 'Accounting' },
    { value: 'Biology', label: 'Biology' },
    { value: 'Chemistry', label: 'Chemistry' },
    { value: 'Physics', label: 'Physics' },
    { value: 'English', label: 'English' },
    { value: 'History', label: 'History' },
    { value: 'Political Science', label: 'Political Science' },
    { value: 'Psychology', label: 'Psychology' },
    { value: 'Sociology', label: 'Sociology' },
    { value: 'Nursing', label: 'Nursing' },
    { value: 'Social Work', label: 'Social Work' },
    { value: 'Public Health', label: 'Public Health' },
    { value: 'Art', label: 'Art' },
    { value: 'Music', label: 'Music' },
    { value: 'Theatre', label: 'Theatre' },
    { value: 'Business', label: 'Business' },
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Finance', label: 'Finance' },
    { value: 'Management', label: 'Management' },
    { value: 'Economics', label: 'Economics' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Education', label: 'Education' },
    { value: 'Health and Human Services', label: 'Health and Human Services' }
  ];

  const roleOptions = [
    { value: 'undergrad', label: 'Undergrad' },
    { value: 'grad', label: 'Graduate' },
    { value: 'alumni', label: 'Alumni' }
  ];

  const pronounsOptions = [
    { value: 'he/him', label: 'he/him' },
    { value: 'she/her', label: 'she/her' },
    { value: 'they/them', label: 'they/them' }
  ];

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  return (
       
    <div className="registration-container">
        
        {stage === 'register' && (
            <form onSubmit={handleRegistration} className="registration-form">
                {error && <div className="error-message">{error}</div>}
                {message && <div className="success-message">{message}</div>}
                {step === 1 && (
                  <>
                    <div className="input-group">
                      <label htmlFor="email">Email</label>
                      <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="input-group">
                      <label htmlFor="password">Password</label>
                      <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                  </>
                )}
                {step === 2 && (
                  <>
                    <div className="input-group">
                      <label htmlFor="first_name">First Name</label>
                      <input type="text" id="first_name" value={first_name} onChange={(e) => setfirst_name(e.target.value)} required />
                    </div>

                    <div className="input-group">
                      <label htmlFor="last_name">Last Name</label>
                      <input type="text" id="last_name" value={last_name} onChange={(e) => setlast_name(e.target.value)} required />
                    </div>
                  </>
                )}
                {step === 3 && (
                  <>
                  <div className="input-group">
                    <label htmlFor="role">Role</label>
                    <Select
                      id="role"
                      options={roleOptions}
                      onChange={(option) => setRole(option.value)}
                      isSearchable />
                  </div>
                  <div className="input-group">
                      <label htmlFor="major">Major</label>
                      <Select
                        id="major"
                        options={majorOptions}
                        onChange={(option) => setMajor(option.value)}
                        isSearchable />
                  </div>
                  </>
                )}
                {step === 4 && (
                  <>
                    <div className="input-group">
                        <label htmlFor="graduation-year">Graduation year</label>
                        <input type="text" id="graduation-year" value={graduationYear} onChange={(e) => setGraduationYear(e.target.value)} required />
                    </div>
                    <div className="input-group">
                    <label htmlFor="pronouns">Pronoun</label>
                    <Select
                        id="pronouns"
                        options={pronounsOptions}
                        onChange={(option) => setPronouns(option.value)}
                        isSearchable
                    />
                    </div>
                  </>
                )}
                {step === 5 && (
                  <>
                    <div className="input-group">
                      <label htmlFor="bio">Bio</label>
                      <textarea className="text-box" type="text" id="bio" value={bio} onChange={(e) => setBio(e.target.value)} />
                    </div>
                    <label>Upload profile pic</label>
                    <input type="file" onChange={onFileChange} />
                  </>
                )}
                <div className="register-button-wrapper">
                  {step > 1 && (
                  <button type='button' className="signup-button" onClick={handleBack}>Back</button>
                  )}
                  <button type='button' className="signup-button" onClick={(event) => { event.preventDefault(); handleNext(); handleRegistration(event); onFileUpload(); }}>{step === 5 ? 'Register' : 'Next'}</button>
                </div>
            </form>
            )}
      {stage === 'verify' && (
          <form onSubmit={handleVerification} className="verify-form">
            <h3>Verify your account. Check email for verification code</h3>
            <div className="input-group">
              <input type="text" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} required />
            </div>
            <button className="signup-button" type="submit">Verify</button>
          </form>
      )}
    </div>
  );
};
export default Register;