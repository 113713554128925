import React, { useState, useEffect } from 'react';
import './postForm.css'

function PostForm({ postOrResources, targetGroupKind, targetGroupId, author, targetAudience, setRefreshFeed }) {
    const [content, setContent] = useState('');
    const [link, setLink] = useState('');
    const [showForm, setShowForm] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newPost = {
            author,
            content,
            link,
            postOrResources,
            targetGroup: {
              kind: targetGroupKind,
              item: targetGroupId
            },
            targetAudience
          };

        const response = await fetch('https://server.theroostscsu.org/api/createPost', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newPost),
        });

        if (response.ok) {
            setContent('');
            setLink('');
            setRefreshFeed(true);
        } else {
            console.error('Failed to create post');
        }
    };

    useEffect(() => {
        console.log('showForm:', showForm);
      }, [showForm]);

    return (
        <div className="post-form-container">
            <div className="post-form-button" style={{ display: showForm ? 'none' : 'flex' }} onClick={() => { console.log('Div clicked'); setShowForm(true); }}>
                <p>Post materials</p>
                <img className="post-form-img" src="/images/post.png" alt="Open form" />
            </div>
            {showForm && (
                <form onSubmit={handleSubmit} className="post-form">
                    <div className="post-form-header">
                        <h2>Create a post</h2>
                        <button className="post-form-close" type="button" onClick={() => setShowForm(false)}>x</button>
                    </div>
                    <textarea
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        placeholder="Write something"
                    />
                    <input
                        type="text"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        placeholder="Post a link"
                    />
                <button className="post-form-submit" type="submit">Create</button>
            </form>
            )}
        </div>
    );
}

export default PostForm;
