import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RegisterForm from '../Register/Register';
import LoginForm from '../Login/LoginForm';
import './LandingPage.css';

function LandingPage({handleLogin}) {
  const [selection, setSelection] = useState('');
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    '/images/scsu2.jpg',
    '/images/scsu3.jpg',
    '/images/scsu4.jpg',
    '/images/scsu5.jpg',
    '/images/scsu6.jpg'
];
  const [showSlideshow, setShowSlideshow] = useState(true);

// dsa

  useEffect(() => {
    const timer = setInterval(() => {
        setCurrentImage((currentImage + 1) % images.length);
    }, 6000); // change img every 6s

    return () => clearInterval(timer);
  }, [currentImage]);

  return (
    <div className="landing">
      <div className="landing-container">
        <h1 className="landing-title">The Roost</h1>
        <div className="landing-right">
        {showSlideshow && (
          <div className="landing-slides">
            <img 
              key={images[currentImage]} 
              className="landing-slides" 
              src={process.env.PUBLIC_URL + images[currentImage]} 
              alt="Slideshow" 
            />
          </div>
        )}
        <div className="landing-text-buttons">
          <h2>Connect with your Southern peers</h2>
          <div className="landing-form">
            {selection === 'login' && (
              <div>
                <LoginForm onLogin={handleLogin} navigate={navigate} />
              </div>
            )}
            {selection === 'register' && (
              <div>
                <RegisterForm />
              </div>
            )}
          </div>
          <div className="landing-buttons">
            {selection === 'login' ? (
              <>
                <p className="landing-p">Don't have an account yet?</p>
                <button className="landing-button2" onClick={() => setSelection('register')}>Register now</button>
              </>
            ) : selection === 'register' ? (
              <>
                <p className="landing-p">Already have an account?</p>
                <button className="landing-button2" onClick={() => setSelection('login')}>Sign in</button>
              </>
            ) : (
              <div className="landing-buttons-default">
              <>
                <button className="landing-button" onClick={() => {setSelection('login'); setShowSlideshow(false);}}>Sign in</button>
                <button className="landing-button" onClick={() => {setSelection('register'); setShowSlideshow(false);}}>Register</button>
              </>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default LandingPage;
