import React, { useState, useEffect } from 'react';
import Feed from '../Feed/Feed';
import Leaderboard from '../Leaderboard/Leaderboard';
import './Homepage.css';


function HomePage({onLogout}) {

  return (
    <div className='homepage-container'>


      <div className='left-announcements'> <Feed/> </div>

      <div className='right-leaderboard'> <Leaderboard/> </div>

    </div>
  );
}
export default HomePage;