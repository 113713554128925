import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './LoginForm.css'; 

const Login = ({onLogin}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const response = await axios.post('https://server.theroostscsu.org/auth/login', {
        email: email,
        password: password
      });
    console.log("Login Successful");
    const token = response.data.token;
    const userId = response.data.userId;  
    console.log(token);
    console.log(userId);
    onLogin(token, userId);
    setError('');
    navigate('/homepage');
      
    } catch (error) {
      if(error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
        console.log(error);
      } else {
        setError('Login failed. Please try again.');
        console.log(error);
      }
    }
  };


  return (
        <div className="login-container"> 
          <form onSubmit={handleSubmit} className="login-form">
          {error && <div className="error-message">{error}</div>}
            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </div>
            <button type="submit" className="login-button">Sign in</button>
          </form> 
        </div>
    );
  };

export default Login;