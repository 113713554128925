import React, { useState } from 'react';

function CreateTaskForm({ mentorshipId, setShowTaskForm, addTask, showTaskForm }) {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [completed, setCompleted] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const newTask = { title, description, dueDate, completed };
    
        addTask(newTask);
    
        const response = await fetch(`https://server.theroostscsu.org/api/mentorship/${mentorshipId}/tasks`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newTask)
        });
    
        setShowTaskForm(false);
    };
    
    

    return (
        <form className="task-form" onSubmit={handleSubmit}>
            <div className="task-form-details">
                <label>Title</label>
                <input type="text" value={title} placeholder="Title" onChange={(e) => setTitle(e.target.value)} required />
                <label>Description</label>
                <textarea value={description} placeholder="Description" onChange={(e) => setDescription(e.target.value)} required />
                <label>Due:
                    <input type="date" value={dueDate} min={new Date().toISOString().split('T')[0]} onChange={(e) => setDueDate(e.target.value)} required />
                </label>
            </div>
            <input type="image" src="/images/create.png" alt="Submit" className="create-task-submit" />
        </form>
    );

}

export default CreateTaskForm;