import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import Homepage from './components/Homepage/Homepage';
import Navbar from './components/Nav/navbar';
import Sidebar from './components/Nav/sidebar';
import MentorSignup from './components/Mentor/mentorSignup';
import MentorSignupForm from './components/Mentor/mentorSignupForm';
import MatchesList from './components/Mentor/MatchesList';
import Mentorship from './components/Mentor/Mentorship';
import './App.css';
import Profile from './components/Profile/Profile';
import EditProfile from './components/EditProfile/EditProfile';
import Admin from './components/Admin/Admin';
import DepartmentPage from './components/DepartmentPage/DepartmentPage';

function MainContent({ onLogout, userId}) {
  const location = useLocation();

  return (
    <>
      <Navbar userId={userId} onLogout={onLogout}/>
      <div className="main-content">
        {(location.pathname !== '/mentorSignup' && location.pathname !== '/mentorSignupForm' && location.pathname !== '/matches' && location.pathname !== '/admin') && <Sidebar />}
        <Routes>
          <Route path="/homepage" element={<Homepage onLogout={onLogout}/>} />
          <Route path="/mentorSignup" element={<MentorSignup />} />
          <Route path="/mentorSignupForm" element={<MentorSignupForm />} />
          <Route path="/matches" element={<MatchesList />} />
          <Route path="/mentorship/:id" element={<Mentorship />} />
          <Route path="/profile/:userId" element={ <Profile onLogout={onLogout} />}/>
          <Route path="/department/:department" element={ <DepartmentPage onLogout={onLogout} />}/>
          <Route path="/editprofile" element={ <EditProfile userId={userId} onLogout={onLogout} />}/>
          <Route path="/admin" element={ <Admin userId={userId} onLogout={onLogout} />}/>
        </Routes>
      </div>
    </>
  );
}

function App() {
  const [data, setData] = useState('');
  const [userId, setUserId] = useState(localStorage.getItem('userId'));
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // useEffect(() => {
  //   const token = localStorage.getItem('userToken');
  //   if (token) {
  //     setIsLoggedIn(true);
  //   }
  // }, []);
  // modified hook to log out user if token is expired
  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
        fetch('https://server.theroostscsu.org/protected', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (response.ok) {
                setIsLoggedIn(true);
            } else {
                throw new Error('Session ended');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            localStorage.removeItem('userToken');
            setIsLoggedIn(false);
        });
    }
}, []);

  const handleLogin = (token, id) => {
    localStorage.setItem('userToken', token);
    localStorage.setItem ('userId', id);  
    setIsLoggedIn(true);
    setUserId(localStorage.getItem('userId'));
    console.log('Login successful, state updated');
  };

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userId');  
    setIsLoggedIn(false);
    setUserId(null);
    console.log('Logout successful, state updated');
  };

  useEffect(() => {
    fetch('https://server.theroostscsu.org')
      .then(response => response.text())
      .then(data => setData(data))
      .catch(error => console.error('Error:', error));
  }, []);

  return (
    <Router>
      <div>
        {isLoggedIn ? (

          <MainContent onLogout={handleLogout} userId={userId}/>
          
        ) : (
          <Routes>
            <Route path="/" element={<LandingPage handleLogin={handleLogin}/>} />
            <Route path="*" element={<Navigate to="/" />} /> 
            {/* comment out line above if redirect after expired token isn't working */}
          </Routes>
        )}
      </div>
    </Router>
  );
  
}

export default App;
