import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Feed.css';  

function Feed() {
  const [items, setItems] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); 

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get('https://server.theroostscsu.org/homepage', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          },
        });
        setItems(response.data); 
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, []);

  if (loading) return <div style={{ backgroundColor: '#1E3344', height: '100vh' }}>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="homepage-content">
      <div className='announcements-container'>
        <div className='gen-announcements'>General Announcements</div>
        {items.map((item) => {
          const date = new Date(item.date);
          const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    
          return (
            <div className="announcements-container2">
              <div key={item.id} className='announcements'> 
                <h3>{item.title}</h3>
                <p>{formattedDate}</p> 
                <p>{item.content}</p> 
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Feed;