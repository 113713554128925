import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const ProfilePicture = ({ profilePicture, onLogout, userId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const navigate = useNavigate();

  const handleLogout = (event) => {
    event.preventDefault();
    onLogout();
    navigate('/');
  };

  const toggleOverlay = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="profile-picture-container">
      <img className="sidebar-profile-picture" src={profilePicture} alt="Profile" onClick={toggleOverlay} />
      <div ref={sidebarRef} className={`profile-picture-sidebar ${isOpen ? 'open' : ''}`}>
        <div className="profile-picture-header">
          <img src={profilePicture} alt="Profile" className="sidebar-profile-picture" />
          <button onClick={toggleOverlay} className="close-button">x</button>
        </div>
        <div className="profile-picture-content">
          <div className="profile-picture-divider"></div>
          <Link to={`/profile/${userId}`}>Profile</Link>
          <Link to ="/editprofile">Edit Profile</Link> 
          <div className="profile-picture-divider"></div>
          <a href="#">About us</a>
          <Link to="/contact">Contact</Link>
          <div className="profile-picture-divider"></div>
          <a href="#" onClick={handleLogout}>Sign out</a>
        </div>
      </div>
    </div>
  );
};

export default ProfilePicture;