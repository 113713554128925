import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './mentorship.css';
import CreateMeetingForm from './CreateMeetingForm';
import CreateTaskForm from './CreateTaskForm';
import MentorFeed from './MentorFeed';

function Mentorship() {
    const { id } = useParams();
    const [mentorship, setMentorship] = useState(null);
    const [user, setUser] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [mentor, setMentor] = useState(null);
    const [mentee, setMentee] = useState(null);
    const [showMeetingForm, setShowMeetingForm] = useState(false);
    const [showTaskForm, setShowTaskForm] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [meetings, setMeetings] = useState([]);

    useEffect(() => {
        const fetchMentorshipAndUser = async () => {
            const token = localStorage.getItem('userToken');

            const responseMentorship = await fetch(`https://server.theroostscsu.org/api/mentorship/${id}`);
            const dataMentorship = await responseMentorship.json();
            console.log('dataMentorship:', dataMentorship);
            setMentorship(dataMentorship);

            setMentor(dataMentorship.mentor);
            setMentee(dataMentorship.mentee);

            const responseUser = await fetch('https://server.theroostscsu.org/navbar/top-navbar-details', {
                headers: {
                    'userToken': 'Bearer ' + token
                }
            });
            const dataUser = await responseUser.json();
            setUser(dataUser);

            setUserRole(dataMentorship.mentor._id === dataUser._id ? 'mentor' : 'mentee');
        };

        fetchMentorshipAndUser();
    }, [id]);


    const uncompletedTasks = mentorship && mentorship.tasks.filter(task => !task.completed);

    const completeTask = async (taskId) => {
        const response = await fetch(`https://server.theroostscsu.org/api/mentorship/${id}/tasks/${taskId}/complete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'userToken': 'Bearer ' + localStorage.getItem('userToken')
            }
        });

        if (response.ok) {
            const updatedMentorship = await response.json();
            setMentorship(updatedMentorship);
        } else {
            console.error('Failed to complete task');
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    function convertTo12Hour(time) {
        const [hour, minute] = time.split(':');
        return ((hour % 12 || 12) + ':' + minute + ' ' + (hour < 12 ? 'AM' : 'PM'));
    }

    const deleteMeeting = async (meetingId) => {
        const response = await fetch(`https://server.theroostscsu.org/api/mentorship/${id}/meetings/${meetingId}/delete`, {
            method: 'DELETE',
            headers: {
                'userToken': 'Bearer ' + localStorage.getItem('userToken')
            }
        });
    
        if (response.ok) {
            const updatedMentorship = await response.json();
            setMentorship(updatedMentorship);
        } else {
            console.error('Failed to delete meeting');
        }
    };
    
    const deleteTask = async (taskId) => {
        const response = await fetch(`https://server.theroostscsu.org/api/mentorship/${id}/tasks/${taskId}/delete`, {
            method: 'DELETE',
            headers: {
                'userToken': 'Bearer ' + localStorage.getItem('userToken')
            }
        });
    
        if (response.ok) {
            const updatedMentorship = await response.json();
            setMentorship(updatedMentorship);
        } else {
            console.error('Failed to delete task');
        }
    };

    const addTask = (task) => {
        setMentorship(prevMentorship => {
            const updatedTasks = [...prevMentorship.tasks, task];
            return { ...prevMentorship, tasks: updatedTasks };
        });
    }
    
    const addMeeting = (meeting) => {
        setMentorship(prevMentorship => {
            const updatedMeetings = [...prevMentorship.meetings, meeting];
            return { ...prevMentorship, meetings: updatedMeetings };
        });
    }

    return (
        <div className="mentorship-content">
            <h1>Mentor</h1>
            <div className="mentorship-container">
                <div className="materials-and-chat">
                    <div className="mentor-materials">
                        {user && <MentorFeed mentorshipId={id} currentUserId={user._id}/>}
                    </div>
                </div>
                <div className="mentorship-divider"></div>
                <div className="mentorship-col2">
                    <div className="mentorship-info">
                        <div className="mentorship-info-col1">
                            <Link to={`/profile/${userRole === 'mentee' && mentor ? mentor._id : mentee && mentee._id}`}>
                                <img className="mentor-pfp" src={userRole === 'mentee' && mentor ? mentor.profilePicture : mentee && mentee.profilePicture} alt="Profile" />
                            </Link>
                        </div>
                    <div className="mentorship-info-col2">
                        <h2>{userRole === 'mentee' && mentor ? `${mentor.firstName} ${mentor.lastName}` : mentee && mentee.firstName ? `${mentee.firstName} ${mentee.lastName}` : ''}</h2>
                        <div className="mentorship-info-details">
                            <p>
                                {userRole === 'mentee' && mentor ? `${mentor.profile.major} | Class of ${mentor.profile.graduationYear} | ${mentor.profile.pronouns}` :
                                mentee && mentee.profile ? `${mentee.profile.major} | Class of ${mentee.profile.graduationYear} | ${mentee.profile.pronouns}` : ''}
                            </p>
                        </div>
                    </div>
                    </div>
                        <div className="mentorship-meetings">
                            <h2>Meetings</h2>
                            {showMeetingForm && <CreateMeetingForm mentorshipId={id} setMeetings={setMeetings} setShowMeetingForm={setShowMeetingForm} addMeeting={addMeeting} showMeetingForm={showMeetingForm}/>}
                            {mentorship && mentorship.meetings.length > 0 ? (
                                mentorship.meetings.map((meeting, index) => (
                                    <div key={index} className="meeting">
                                        <div className="meeting-details">
                                            <p>
                                                {capitalizeFirstLetter(meeting.frequency)} {' '}
                                                {meeting.method} {' '}
                                                {meeting.frequency === 'one time' ? 'meeting on' : 'meetings on'} {' '}
                                                {meeting.days.map(day => capitalizeFirstLetter(day)).join(', ')}
                                                {' at '} {convertTo12Hour(meeting.startTime) + ' - ' + convertTo12Hour(meeting.endTime)}
                                            </p>
                                        </div>
                                        {userRole === 'mentor' && (
                                            // <button className="delete-meeting-button" onClick={() => deleteMeeting(meeting._id)}>Delete meeting</button>
                                            <img 
                                                src="/images/delete.png" 
                                                alt="Delete meeting" 
                                                className="task-delete-button" 
                                                onClick={() => deleteMeeting(meeting._id)}
                                            />
                                        )}
                                    </div>
                                ))
                            ) : (
                                userRole === 'mentor' && !showMeetingForm && (
                                    <div>
                                        <p>No meetings yet</p>
                                        <button className="create-meeting-button" onClick={() => setShowMeetingForm(true)}>Create a meeting</button>
                                    </div>
                                )
                            )}

                            {userRole === 'mentor' && mentorship && mentorship.meetings.length > 0 && !showMeetingForm && (
                                <button className="create-meeting-button" onClick={() => setShowMeetingForm(true)}>Create a meeting</button>
                            )}
                        </div>
                        <div className="mentorship-tasks">
                        <h2>Tasks</h2>
                        {showTaskForm && <CreateTaskForm mentorshipId={id} addTask={addTask} setShowTaskForm={setShowTaskForm} showTaskForm={showTaskForm}/>}
                        <div className="mentorship-tasks-container">
                            {uncompletedTasks && uncompletedTasks.length > 0 ? (
                                uncompletedTasks.map((task, index) => (
                                    <div key={index} className="mentorship-task">
                                        <div className="task-details">
                                            <h3>{task.title}</h3>
                                            <p>{task.description}</p>
                                            <p>Due: {new Date(task.dueDate).toLocaleDateString()}</p>
                                        </div>
                                        {userRole === 'mentee' && (
                                            // <button className="task-complete-button" onClick={() => completeTask(task._id)}>Complete task</button>
                                            <img 
                                                src="/images/complete.png" 
                                                alt="Complete task" 
                                                className="task-delete-button" 
                                                onClick={() => completeTask(task._id)}
                                            />
                                        )}
                                        {userRole === 'mentor' && (
                                            <img 
                                                src="/images/delete.png" 
                                                alt="Delete task" 
                                                className="task-delete-button" 
                                                onClick={() => deleteTask(task._id)}
                                            />
                                        )}
                                    </div>
                                ))
                            ) : (
                                userRole === 'mentor' && !showTaskForm && (
                                    <div>
                                        <p>No tasks yet.</p>
                                        <button className="create-task-button" onClick={() => setShowTaskForm(true)}>Create a task</button>
                                    </div>
                                )
                            )}
                        </div>
                        {userRole === 'mentor' && uncompletedTasks && uncompletedTasks.length > 0 && !showTaskForm && (
                            <button className="create-task-button" onClick={() => setShowTaskForm(true)}>Create a task</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mentorship;