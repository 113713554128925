import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './mentorship.css'
import PostForm from '../Post/PostForm';

function MentorFeed({ mentorshipId, currentUserId }) {
    const [mentorFeed, setMentorFeed] = useState([]);
    const [refreshFeed, setRefreshFeed] = useState(false);

    useEffect(() => {
        const fetchMentorFeed = async () => {
            // groupType is case sensitive
            const responseMentorFeed = await fetch(`https://server.theroostscsu.org/Mentorship/${mentorshipId}/resources`);
            const dataMentorFeed = await responseMentorFeed.json();
            setMentorFeed(dataMentorFeed);
        };
        fetchMentorFeed();
        setRefreshFeed(false);
    }, [mentorshipId, refreshFeed]);

    return (
        <div className="mentor-feed-container">
            <PostForm postOrResources="resources" targetGroupKind="Mentorship" targetGroupId={mentorshipId} author={currentUserId} targetAudience="mentorship" setRefreshFeed={setRefreshFeed}/>
            {mentorFeed.slice().reverse().map((feedItem) => (
                <div key={feedItem._id} className="mentor-feed-item">
                    <div className="mentor-feed-header">
                        <Link to={`/profile/${feedItem.author._id}`}>
                            <img className="mentor-feed-pfp" src={feedItem.author.profilePicture} alt="profile" />
                        </Link>
                        <p>{feedItem.author.firstName} {feedItem.author.lastName}</p>
                    </div>
                    <div className="mentor-feed-content">
                        <p>{feedItem.content}</p>
                    </div>
                    <p>{new Date(feedItem.date).toLocaleDateString()}</p>
                </div>
            ))}
        </div>
    );
}

export default MentorFeed;