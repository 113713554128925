import React, { useState, useEffect } from 'react';
import altpic from './pfp.png'; 
import { Link } from 'react-router-dom';
import './Leaderboard.css';

function Leaderboard() {

  const [scores, setScores] = useState([]);

  useEffect(() => {
      fetch('https://server.theroostscsu.org/user/top-scores') 
          .then(response => response.json())
          .then(data => setScores(data))
          .catch(err => console.error('Error fetching scores:', err));
  }, []);

  function ImageWithFallback({ src, fallbackSrc, alt }) {
    const handleError = (event) => {
        console.log('Error loading primary image. Switching to fallback.');
        if (event.target.src !== fallbackSrc) {  
            event.target.src = fallbackSrc;
        }
    };

    return (
        <img src={src} alt={alt} onError={handleError} className='leaderboard-picture'/>
    );
  }

  return (
      <div>
          <div className='leaderboard-header'>Leaderboard</div>

            <div className='leaderboard-container'>

              <div className='leaderboard-descriptor'>
              These are our top contributors! <br></br><br></br>
              Participate to get your name on the leaderboard!
              </div>
          
              {scores.map((user, index) => (
           
                  <div className="leaderboard-profile"key={index}>

                  <ImageWithFallback
                  src={user.profilePicture}
                  fallbackSrc={altpic}
                  alt="Profile"
                  />
                  <div className='leaderboard-name'> <Link to ={`/profile/${user._id}`}>  {user.firstName} {user.lastName}</Link></div>
                  </div>
              
              ))}
            </div>
      </div>
  );

}

export default Leaderboard;