import React from 'react';
import { useNavigate } from 'react-router-dom';
import './mentorSignup.css';

function MentorSignup() {
    const navigate = useNavigate();

    const handleSignupClick = () => {
        navigate('/mentorSignupForm');
    };

    return (
        <div className="content">
            <h1>Achieve more together by joining a mentorship</h1>
            <div className="cards">
                <div className="card">
                    <h2>Find a mentor</h2>
                    <p>
                        Seeking guidance from someone who has walked a similar path can 
                        be invaluable. Whether navigating academic challenges or 
                        career decisions, finding a mentor can provide invaluable 
                        insights and support. A mentor can offer practical advice, 
                        share their experiences, and help you navigate the complexities 
                        of college life and beyond. By connecting with a mentor, 
                        you gain access to a wealth of knowledge and a trusted 
                        confidant who can help you unlock your full potential.
                    </p>
                </div>
                <div className="card">
                    <h2>Be a mentor</h2>
                    <p>
                        Being a mentor is an opportunity to make a 
                        meaningful impact on someone else's journey. By sharing your 
                        own experiences, successes, and failures, you can provide guidance 
                        and support to a fellow student facing similar challenges. Being a 
                        mentor isn't just about offering advice; it's about being a source 
                        of encouragement, motivation, and empowerment. As a mentor, you have 
                        the chance to shape someone else's future, foster their growth, and 
                        leave a lasting legacy of support and mentorship.
                    </p>
                </div>
            </div>
            <button className="signup-button1" onClick={handleSignupClick}>Get started</button>
        </div>
    );
}

export default MentorSignup;