import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EditProfile.css';

function EditProfile({ userId }) {
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    profile:{ bio: ''}
  });

  useEffect(() => {
    // Fetch the user's profile information from the backend API
    axios.get(`https://server.theroostscsu.org/profile/user/${userId}`)
      .then(response => {
        // Assuming the response data is the user object directly
        setUser(response.data);
      })
      .catch(error => console.error('Error fetching user data:', error));
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "bio") {
      // Handle nested state update for bio within profile
      setUser(prevUser => ({
        ...prevUser,
        profile: {
          ...prevUser.profile,
          bio: value
        }
      }));
    } else {
      setUser(prevUser => ({
        ...prevUser,
        [name]: value
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Confirm before submitting the form
    if (window.confirm("Are you sure you want to save these changes?")) {
      // Update the user profile information on the backend API
      axios.put(`https://server.theroostscsu.org/profile/${userId}`, user)
        .then(() => {
          alert('Profile updated successfully!');
        })
        .catch(error => {
          console.error('Error updating profile:', error);
          alert('Failed to update profile.');
        });
    } else {
      // User clicked 'Cancel', handle accordingly
      console.log("User canceled the update.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className='edit-profile'>
      <div className='edit-profile-container'>
        <h1>Edit Profile</h1>
        <label>
          First Name:<br></br>
          <input type="text" name="firstName" value={user.firstName} onChange={handleChange} className='edit-input' />
        </label>
        <label>
          Last Name:<br></br>
          <input type="text" name="lastName" value={user.lastName} onChange={handleChange} className='edit-input' />
        </label>
        <label>
          Email:<br></br>
          <input type="email" name="email" value={user.email} onChange={handleChange} className='edit-input' />
        </label>
        <label>
          Bio:<br></br>
          <textarea name="bio" value={user.profile.bio} onChange={handleChange} className='edit-input' />
        </label>
        <button type="submit" className='edit-profile-button'>Save Changes</button>
      </div>
    </form>
  );
}

export default EditProfile;