import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Admin() {
    const navigate = useNavigate();
    const [admin, setAdmin] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [posts, setPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [selectedComment, setSelectedComment] = useState(null);
    const [chatMessages, setChatMessages] = useState([]);
    const [mentorships, setMentorships] = useState([]);
    const [selectedMentorship, setSelectedMentorship] = useState(null);

    useEffect(() => {
        const fetchAdmin = async () => {
            try {
                const token = localStorage.getItem('userToken');
                const responseAdmin = await fetch('https://server.theroostscsu.org/navbar/top-navbar-details', {
                    headers: {
                        'userToken': 'Bearer ' + token
                    }
                });
                const textAdmin = await responseAdmin.text();
                if (responseAdmin.ok && textAdmin.trim().startsWith('{')) {
                    const dataAdmin = JSON.parse(textAdmin);
                    setAdmin(dataAdmin);
        
                    if (dataAdmin.isAdmin) {
                        setIsAdmin(true);
                    } else {
                        navigate('/homepage');
                    }
                } else {
                    console.error('Server response:', textAdmin);
                }
            } catch (error) {
                console.error('Error fetching admin details:', error);
                console.log('Error occurred at:', error.stack);
            }
        }
        
        fetchAdmin();
    }, [navigate]);

    return (
        <div className="admin-content">
            <h1>Admin dashboard</h1>
            <div className="admin-users">
                <h2>Users</h2>
                {/* <ul>
                    {users.map(user => (
                        <li key={user._id} onClick={() => setSelectedUser(user._id)}>{user.username}</li>
                    ))}
                </ul> */}
            </div>
        </div>
    )
}

export default Admin;