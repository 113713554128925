import React, { useState, useEffect, useRef } from 'react';

const bell = '/images/bell.png';

const NotificationBell = ({ notifications }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = (event) => {
        event.stopPropagation();
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="parent-div">
            <img className="bell" src={bell} alt="Notification Bell" onClick={toggleDropdown} />
            {isOpen && (
                <div className="notification-dropdown" ref={dropdownRef}>
                    <ul>
                        {notifications.map((notification) => (
                            <li key={notification.id}>{notification.message}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default NotificationBell;