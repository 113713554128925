import React, { useState } from 'react';
import Select from 'react-select';

function CreateMeetingForm({ mentorshipId, setShowMeetingForm, addMeeting, showMeetingForm }) {
    const [selectedDays, setSelectedDays] = useState([]);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [frequency, setFrequency] = useState('weekly');
    const [method, setMethod] = useState('in person');
    const [date, setDate] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const newMeeting = { frequency, days: selectedDays, method, startTime, endTime, date };
    
        addMeeting(newMeeting);
    
        const response = await fetch(`https://server.theroostscsu.org/api/mentorship/${mentorshipId}/meetings`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newMeeting)
        });

        setShowMeetingForm(false);
    };
    
    const daysOfWeek = [
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' },
        { value: 'Sunday', label: 'Sunday' },
    ];

    const handleSelectChange = (selectedOptions) => {
        setSelectedDays(selectedOptions.map(option => option.value));
    };

    const meetingStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#7BBAB2',
            border: 'none',
            minHeight: '30px',
            height: '38px',
            boxShadow: 'none',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 14px'
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: '1px',
            color: 'white',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
        }),
        option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
            ...provided,
            padding: '10px 12px',
            backgroundColor: isSelected
            ? '#7BBAB2'
            : isFocused
                ? '#7BBAB2'
                : null,
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#7BBAB2'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: '14px', // replace '16px' with the font size you want
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'white',
            fontSize: '14px', // replace '16px' with the font size you want
        })
    };

    return (
        <form className="meeting-form" onSubmit={handleSubmit}>
            <div className="meeting-form-details">
                <label>Days</label>
                <Select 
                    styles={meetingStyles}
                    options={daysOfWeek} 
                    isMulti 
                    onChange={handleSelectChange} 
                />
                <label>Start time</label>
                <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} required />
                <label>End time</label>
                <input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} required />
                <label>Frequency</label>
                <select value={frequency} onChange={(e) => setFrequency(e.target.value)} required>
                    <option value="one time">One time</option>
                    <option value="weekly">Weekly</option>
                    <option value="bi-weekly">Bi-Weekly</option>
                </select>
                {frequency === 'one time' && (
                    <>
                        <label>Date</label>
                        <input type="date" min={new Date().toISOString().split('T')[0]} value={date} onChange={(e) => setDate(e.target.value)} required />
                    </>
                )}
                <label>Method</label>
                <select value={method} onChange={(e) => setMethod(e.target.value)} required>
                    <option value="in person">In person</option>
                    <option value="phone">Phone</option>
                    <option value="video call">Video call</option>
                </select>
            </div>
            <input type="image" src="/images/create.png" Alt="submit" className="create-task-submit"/>
        </form>
    );
}

export default CreateMeetingForm;