import React, { useState } from 'react';
import { useEffect } from 'react';
import './matchesList.css';

function MatchesList() {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [animating, setAnimating] = useState(false);
    const [currentMatch, setCurrentMatch] = useState(0);
    const [matches, setMatches] = useState([]);
    const [animationClass, setAnimationClass] = useState('');
    const [interestSent, setInterestSent] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const token = localStorage.getItem('userToken');
                // reuse get request from navbarService
                const response = await fetch('https://server.theroostscsu.org/navbar/top-navbar-details', {
                    headers: {
                        'userToken': 'Bearer ' + token
                    }
                });
                if (response.ok) { // Check if the response is ok
                    const data = await response.json(); // Read and parse the response body
                    setUser(data);
                    console.log('User:', data); // Log the user data
                    setLoading(false);
    
                    const fetchMatches = async () => {
                        const response = await fetch('https://server.theroostscsu.org/api/matches/matches', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ user: data }),
                        });
                        const text = await response.text();
                        console.log('Server response:', text);
                        try {
                            const matchesData = JSON.parse(text);
                            setMatches(matchesData);
                            console.log(matchesData);
                        } catch (error) {
                            console.error('Error parsing server response:', error);
                        }
                    };
    
                    fetchMatches();
                } else {
                    console.error('Server response:', response.statusText); // Log the server response status text
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };
    
        fetchUser();
    }, []);

    useEffect(() => {
        if (matches.length > 0) {
            setAnimationClass('fade-in');
        }
    }, [currentMatch]);

    const getCommonCriteria = (userCriteria, matchCriteria) => {
        const commonCriteria = [];
        const keyToEnglish = {
            academicInterests: 'have an academic interest in',
            careerGoals: 'working towards a career goal of',
            extracurricularActivities: 'participate in',
            hobbies: 'enjoy',
            skills: 'would like to work on',
            gender: '',
            availability: 'available on',
            communicationPreference: 'prefer to communicate'
        };
        const keyToEnglishPlural = {
            academicInterests: 'have academic interests in',
            careerGoals: 'working towards career goals of',
            extracurricularActivities: 'participate in',
            hobbies: 'enjoy',
            skills: 'would like to work on',
            gender: '',
            availability: 'available on',
            communicationPreference: 'prefer to communicate'
        };
    
        for (const key in userCriteria) {
            if (userCriteria[key].value && matchCriteria[key].value && Array.isArray(userCriteria[key].value) && Array.isArray(matchCriteria[key].value)) {
                // check for common elements
                const commonElements = userCriteria[key].value.filter(element => matchCriteria[key].value.includes(element));
                if (commonElements.length > 0) {
                    const formattedCommonElements = commonElements.map(element => element === element.toUpperCase() ? element : element.toLowerCase());
                    const lastElement = formattedCommonElements.pop();
                    const formattedElements = formattedCommonElements.length > 0 ? `${formattedCommonElements.join(', ')} and ${lastElement}` : lastElement;
                    const englishKey = commonElements.length > 1 ? keyToEnglishPlural[key] : keyToEnglish[key];
                    const prefix = key === 'gender' ? 'You are both' : 'You both';
                    commonCriteria.push(`${prefix} ${englishKey} ${formattedElements}.`);
                }
            } else if (userCriteria[key] === matchCriteria[key] || (userCriteria[key].value && userCriteria[key].value === matchCriteria[key].value)) {
                // check for equality
                switch (key) {
                    case 'availability':
                        commonCriteria.push(`You are both available on ${userCriteria[key]}.`);
                        break;
                    case 'communicationPreference':
                        commonCriteria.push(`You would both prefer to communicate ${userCriteria[key].frequency} via ${userCriteria[key].method}.`);
                        break;
                    case 'gender':
                        commonCriteria.push(`You are both ${userCriteria[key].value}.`);
                        break;
                    default:
                        const value = userCriteria[key].value || userCriteria[key];
                        const formattedValue = value === value.toUpperCase() ? value : value.toLowerCase();
                        commonCriteria.push(`You both have ${keyToEnglish[key]} ${formattedValue}.`);
                }
            }
        }
    
        return commonCriteria;
    };
    
    useEffect(() => {
        if (animating) {
            setAnimationClass('fade-out');
            setTimeout(() => {
                setCurrentMatch((prevMatch) => (prevMatch < matches.length - 1 ? prevMatch + 1 : 0));
                setAnimating(false);
                setAnimationClass('fade-in');
            }, 500); // Adjust the delay according to your animation duration
        }
    }, [animating, currentMatch, matches]);
    
    
    const handleNext = () => {
        setAnimating(true);
    };
    
    const handlePrev = () => {
        setAnimating(true);
    };

    async function handleInterest() {
        // Fetch the current user role
        const roleResponse = await fetch('https://server.theroostscsu.org/api/matches/currentUserRole', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ user })
        });
    
        const currentUserRole = await roleResponse.json();
        console.log('Current user role:', currentUserRole);

        const mentorId = currentUserRole === 'mentor' ? user._id : matches[currentMatch]._id;
        const menteeId = currentUserRole === 'mentee' ? user._id : matches[currentMatch]._id;
    
        const response = await fetch('https://server.theroostscsu.org/api/request', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mentorId, menteeId })
        });
    
        const data = await response.json();

        const newMatches = [...matches];
        newMatches.splice(currentMatch, 1);
        setMatches(newMatches);
    
        // If there are more matches, load the next match
        if (newMatches.length > 0) {
            setCurrentMatch(currentMatch % newMatches.length);
        }
    }

    return (
        <div className="matches-list">
            <h1>Matches</h1>
            <div className="matches">
                <button className="prev-match" onClick={handlePrev} disabled={animating}>
                    Previous match
                </button>
                {matches.length > 0 ? (
                    <div className={`match ${animating ? 'fade-out' : 'fade-in'}`}>
                        <div className="match-header">
                            <h2>{matches[currentMatch].firstName} {matches[currentMatch].lastName}</h2>
                        </div>
                        <img className="match-pfp" src={matches[currentMatch].profilePicture} alt="profile" />
                        <h3>{matches[currentMatch].firstName}'s bio</h3>
                        <p className="match-bio">{matches[currentMatch].profile.bio}</p>
                        <div className="common-criteria">
                            <h3>Common Criteria</h3>
                            {getCommonCriteria(user.matchingCriteria, matches[currentMatch].matchingCriteria).map((message, index) => (
                                <p key={index}>{message}</p>
                            ))}
                        </div>
                        <button className="match-button" onClick={handleInterest}>Let {matches[currentMatch].firstName} know you're interested</button>
                        {interestSent && <p>Interest sent!</p>}
                    </div>
                ) : (
                    <p>No more matches</p>
                )}
                <button className="next-match" onClick={handleNext} disabled={animating}>
                    Next match
                </button>
            </div>
        </div>
    );
    
}

export default MatchesList;