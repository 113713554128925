import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './sidebar.css';

const Sidebar = () => {
    const [sidebarDetails, setSidebarDetails] = useState(null);
    const [user, setUser] = useState(null);
    const [isMajorExpanded, setIsMajorExpanded] = useState(true);
    const [isClassesExpanded, setIsClassesExpanded] = useState(true);
    const [isStudyGroupsExpanded, setIsStudyGroupsExpanded] = useState(true);
    const [isMentorshipsExpanded, setIsMentorshipsExpanded] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('userToken');
                const response = await fetch('https://server.theroostscsu.org/navbar/sidebar-details', {
                    headers: {
                        'userToken': 'Bearer ' + token
                    }
                });
                const text = await response.text(); // Get the response as text
                if (response.ok && text.trim().startsWith('{')) { // Check if the response is ok and the text is a valid JSON string
                    const data = JSON.parse(text); // Parse the response as JSON
                    setSidebarDetails(data);
                    setUser(data.user);
                    console.log(data);
                } else {
                    console.error('Server response:', text); // Log the server response
                }
            } catch (error) {
                console.error('Error fetching sidebar details:', error);
                console.log('Error occurred at:', error.stack); // Log the error stack
            }
        };

        fetchData();
    }, []);

    if (!sidebarDetails) {
        return <div style={{ backgroundColor: '#1E3344', height: '100vh' }}>Loading...</div>;
    }

    return (
        <aside className="sidebar">
        <div>
            <h2 onClick={() => setIsMajorExpanded(!isMajorExpanded)} className={isMajorExpanded ? 'expanded' : ''}>Major</h2>
            {isMajorExpanded && (
                <ul>
                    
                    <li>
                    <Link to={`/department/${sidebarDetails.major}`}> {sidebarDetails.major ? sidebarDetails.major : 'No major specified'} </Link> 
                    </li>
                </ul>
            )}
        </div>
        <div>
            <h2 onClick={() => setIsClassesExpanded(!isClassesExpanded)} className={isClassesExpanded ? 'expanded' : ''}>Class</h2>
            {isClassesExpanded && (
                <ul>
                    {sidebarDetails.classes && sidebarDetails.classes.length > 0 ? (
                        sidebarDetails.classes.map((item) => (
                            <li key={item.id}>{item.name}
                                
                            
                            </li>
                        ))
                    ) : (
                        <li>No classes</li>
                    )}
                </ul>
            )}
        </div>
        <div>
            <h2 onClick={() => setIsStudyGroupsExpanded(!isStudyGroupsExpanded)} className={isStudyGroupsExpanded ? 'expanded' : ''}>Study</h2>
            <ul>
                {sidebarDetails.studyGroups && sidebarDetails.studyGroups.length > 0 ? (
                    sidebarDetails.studyGroups.map((item) => (
                        <li key={item.id}>{item.name}</li>
                    ))
                ) : (
                    <li>No study groups</li>
                )}
            </ul>
        </div>
        <div>
            <h2 onClick={() => setIsMentorshipsExpanded(!isMentorshipsExpanded)} className={isMentorshipsExpanded ? 'expanded': ''}>Mentor</h2>
            {isMentorshipsExpanded && (
                <ul>
                    {sidebarDetails.mentors && sidebarDetails.mentors.filter(mentorship => mentorship.status === 'active').length > 0 && (
                        <>
                            <h3>Mentors</h3>
                            {sidebarDetails.mentors.map((mentorship) => (
                                <li key={mentorship._id}>
                                    <Link to={`/mentorship/${mentorship._id}`}>{mentorship.mentor.firstName} {mentorship.mentor.lastName}</Link>
                                </li>
                            ))}
                        </>
                    )}
                    {sidebarDetails.mentees && sidebarDetails.mentees.filter(mentorship => mentorship.status === 'active').length > 0 && (
                        <>
                            <h3>Mentees</h3>
                            {sidebarDetails.mentees.map((mentorship) => (
                                <li key={mentorship._id}>
                                    <Link to={`/mentorship/${mentorship._id}`}>{mentorship.mentee.firstName} {mentorship.mentee.lastName}</Link>
                                </li>
                            ))}
                        </>
                    )}
                    {sidebarDetails.mentorships && sidebarDetails.mentorships.some(item => item.status === 'pending') && (
                        <li><Link to='/matches'>View your matches</Link></li>
                    )}
                    {sidebarDetails.mentorships && !sidebarDetails.mentorships.some(item => item.status === 'pending') && sidebarDetails.mentorships.some(item => item.status === 'active') && (
                        <li><Link to='/mentorSignup'>Sign up for a new mentorship</Link></li>
                    )}
                    {sidebarDetails.mentorships && sidebarDetails.mentorships.length === 0 && (
                        <li><Link to='/mentorSignup'>Sign up for a mentorship</Link></li>
                    )}
                </ul>
            )}
        </div>
    </aside>
    );
};

export default Sidebar;
